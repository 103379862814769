<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
import Home from './components/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
body {
  background-image: url('./assets/bg.jpg');
}
#app {
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(39, 38, 37) !important;
  margin-top: 60px;
  font-weight: bold;
}
h1 {
  font-weight: 600 !important;
}
.btn-white {
  border: 2px solid white !important;
  color: rgb(39, 38, 37) !important;
  background-color: #f9f9f9 !important;
  font-size: 18px !important;
  font-weight: bold !important;
}
.btn-white:hover {
  border: 2px solid white !important;
  color: #f9f9f9 !important;
  background-color: rgb(39, 38, 37) !important;
  font-size: 18px !important;
  font-weight: bold !important;
}
p {
  margin-bottom: 0 !important;
}
</style>
