<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="info-wrapper mb-3">
            <img id="photo" src="../assets/falk.jpg" :alt="data.name" class="rounded-circle img-fluid w-15 mb-3">
            <h1 id="name">{{ data.name }}</h1>
            <p id="description">{{ data.description }}</p>
          </div>
          <div id="social" class="social-wrapper mb-4">
            <a :href="social.url" target="_blank" class="mx-2" rel="noopener noreferrer" v-for="(social, index) in data.socials" v-bind:key="index">
              <i :class="'fab ' + social.image"></i>
            </a>
          </div>
          <div class="links-wrapper col-12 col-md-6 offset-md-3">
            <div class="item" v-for="(link, index) in data.links" v-bind:key="index">
              <a :href="link.url" target="_blank" rel="noopener noreferrer" class="d-flex justify-content-center btn btn-white d-block mb-3 links">
                <div class="margin-right"><i :class="'fab ' + link.image"></i></div>
                <div>{{link.title}}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
const axios = require("axios");
export default {
  name: 'Home',
  data() {
    return {
      data: {}
    }
  },
  mounted() {
    axios.get("/data.json").then((res) => {
      this.data = res.data;
      
      setTimeout(function(){
        gsap.from("#photo", {duration: 1, y: 100, opacity: 0, ease: "power3.out"});
        gsap.from("#name", {duration: 1, opacity: 0, ease: "power3.out", delay: .2});
        gsap.from("#description", {duration: 1, opacity: 0, ease: "power3.out", delay: .2});
        gsap.from("#social", {duration: 1, opacity: 0, ease: "power3.out", delay: .2});
        gsap.from(".links", {duration: 1, y: -20, opacity: 0, ease: "power3.out", delay: .2, stagger: 0.1});
      }, 1);
    });
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.margin-right {
  margin-right: 10px;
}
.w-15 {
  max-width: 15%;
}
.social-wrapper a {
  font-size: 28px;
  color: rgba(39, 38, 37) !important;
}
.social-wrapper a:hover {
  color: #f9f9f9 !important;
}
</style>
